import { useLazyQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Recipe } from "containers/Recipes/recipes";
import { RECIPES_LIVE_SEARCH } from "containers/Recipes/recipes.gql";
import { useAuth } from "contexts/auth-context";
import React, { FormEvent, useEffect } from "react";
import { Ban } from "tabler-icons-react";
import { PERMISSIONS } from "utils/permissions";

import { LiveSearchSelect, SelectProps } from "./select";

export default function RecipesSelect({
  disabled,
  ...rest
}: SelectProps): React.ReactElement {
  const { checkPermission } = useAuth();
  const [css] = useStyletron();

  const [fetchData, { data, loading }] = useLazyQuery(RECIPES_LIVE_SEARCH);

  useEffect(() => fetchData(), []);

  const hasPermission = checkPermission(PERMISSIONS.recipe.read);

  return (
    <LiveSearchSelect
      options={data?.recipes?.nodes?.map(({ id, name }: Recipe) => ({
        id,
        label: name,
      }))}
      getValueLabel={({ option }) => `${option?.label}`}
      getOptionLabel={({ option }) => `${option?.label}`}
      onInputChange={(event: FormEvent) => {
        fetchData({
          variables: {
            filter: {
              or: [
                {
                  name: {
                    like: `%${(event.currentTarget as any).value}%`,
                  },
                },
              ],
            },
          },
        });
      }}
      placeholder={
        hasPermission ? (
          "Wybierz"
        ) : (
          <div
            className={css({
              display: "flex",
              alignItems: "center",
              gap: " 5px",
            })}
          >
            <Ban size={16} />
            Brak uprawnień do przeglądania przepisu
          </div>
        )
      }
      disabled={disabled || !hasPermission}
      isLoading={loading}
      {...rest}
    />
  );
}
