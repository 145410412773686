import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { StyledLink } from "baseui/link";
import { Tooltip } from "components/tooltip";
import { CategoryKind } from "containers/Categories/categories.d";
import { useAuth } from "contexts/auth-context";
import React, { MouseEvent } from "react";
import { useHistory } from "react-router";
import { StyleObject } from "styletron-standard";
import {
  Article,
  Book,
  CakeOff,
  Carrot,
  Category,
  ChefHat,
  CircleCheck,
  CircleX,
  ExternalLink,
  Eye,
  EyeOff,
  Ghost,
  Key,
  Lock,
  LockOpen,
  Mail,
  Minus,
  News,
  Photo,
  Pin,
  PinnedOff,
  Registered,
  Salad,
  Seo,
  Shield,
  Slideshow,
  Tags,
  User,
  UserCheck,
  UserExclamation,
  UserOff,
  World,
} from "tabler-icons-react";
import { PERMISSIONS } from "utils/permissions";

import {
  Difficulty,
  SEO_TAGSETS_SUBJECTS,
  STATIC_PAGE_KINDS,
} from "../../constants";

export enum DataType {
  AccessBoolean = "access-boolean",
  ActivityBoolean = "activity-boolean",
  Allergens = "model:allergens",
  Articles = "model:articles",
  Avatars = "model:avatars",
  Boolean = "boolean",
  Calories = "calories",
  Categories = "model:categories",
  CategoryKind = "category-kind",
  Date = "date",
  DateTime = "datetime",
  Difficulty = "difficulty",
  Brands = "model:brands",
  Dictionaries = "model:dictionaries",
  Email = "email",
  Gram = "gram",
  Images = "images",
  InvertedBoolean = "inverted-boolean",
  Ingredients = "model:ingredients",
  Link = "link",
  Login = "login",
  Minutes = "minutes",
  Model = "model:",
  NotificationContent = "notification-content",
  Quota = "quota",
  Pre = "pre",
  Products = "model:products",
  Recipes = "model:recipes",
  Roles = "model:roles",
  SeoTagsets = "model:seo-tagsets",
  Slides = "model:slides",
  StaticPages = "model:static-pages",
  StaticPageKind = "static-page-kind",
  Subject = "subject",
  System = "system",
  Tags = "model:tags",
  VisibilityBoolean = "visibility-boolean",
  Users = "model:users",
  UserStatus = "user-status",
}

type Props = {
  children?: number | string | boolean | Date;
  data?: string | number;
  dataType?: string;
  deletedAt?: Date;
  href?: string;
  $style?: StyleObject;
  textWhenEmpty?: string;
};

export default function FormattedValue({
  children,
  dataType,
  data,
  deletedAt,
  href,
  $style,
  textWhenEmpty,
}: Props): React.ReactElement {
  const [css, theme] = useStyletron();
  const history = useHistory();
  const { checkPermission } = useAuth();

  const hasPermission =
    (dataType?.includes(DataType.Allergens) &&
      checkPermission(PERMISSIONS.allergen.read)) ||
    (dataType?.includes(DataType.Articles) &&
      checkPermission(PERMISSIONS.article.read)) ||
    (dataType?.includes(DataType.Avatars) &&
      checkPermission(PERMISSIONS.avatar.read)) ||
    (dataType?.includes(DataType.Brands) &&
      checkPermission(PERMISSIONS.brand.read)) ||
    (dataType?.includes(DataType.Categories) &&
      checkPermission(PERMISSIONS.category.read)) ||
    (dataType?.includes(DataType.Dictionaries) &&
      checkPermission(PERMISSIONS.dictionary.read)) ||
    (dataType?.includes(DataType.Images) &&
      checkPermission(PERMISSIONS.image.read)) ||
    (dataType?.includes(DataType.Products) &&
      checkPermission(PERMISSIONS.product.read)) ||
    (dataType?.includes(DataType.Ingredients) &&
      checkPermission(PERMISSIONS.ingredient.read)) ||
    (dataType?.includes(DataType.Recipes) &&
      checkPermission(PERMISSIONS.recipe.read)) ||
    (dataType?.includes(DataType.Roles) &&
      checkPermission(PERMISSIONS.role.read)) ||
    (dataType?.includes(DataType.SeoTagsets) &&
      checkPermission(PERMISSIONS.seoTagset.read)) ||
    (dataType?.includes(DataType.Slides) &&
      checkPermission(PERMISSIONS.slide.read)) ||
    (dataType?.includes(DataType.StaticPages) &&
      checkPermission(PERMISSIONS.staticPage.read)) ||
    (dataType?.includes(DataType.Tags) &&
      checkPermission(PERMISSIONS.tag.read)) ||
    (dataType?.includes(DataType.Users) &&
      checkPermission(PERMISSIONS.user.read));

  const iconStyle = {
    verticalAlign: "middle",
    marginRight: "10px",
    display: "inline",
  };

  if (children === null || children === undefined || children === "")
    return (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
          color: "#999999",
          ...$style,
        })}
      >
        {textWhenEmpty || (
          <>
            <Minus
              color="#999999"
              size={18}
              className={css({ marginRight: "5px" })}
            />
            Brak
          </>
        )}
      </div>
    );
  else if (dataType === DataType.AccessBoolean)
    return (
      <Block display="flex" alignItems="center">
        {children ? (
          <Lock
            size={14}
            className={css({
              ...iconStyle,
              marginTop: "-2px",
              color: theme.colors.negative,
            })}
          />
        ) : (
          <LockOpen
            size={14}
            className={css({
              ...iconStyle,
              marginTop: "-2px",
              color: theme.colors.positive,
            })}
          />
        )}
        {children ? "Tak" : "Nie"}
        <span hidden>{children.toString()}</span>
      </Block>
    );
  else if (dataType === DataType.ActivityBoolean)
    return (
      <Block>
        {children ? (
          <Pin
            size={14}
            className={css({
              ...iconStyle,
              marginTop: "-2px",
              color: theme.colors.positive,
            })}
          />
        ) : (
          <PinnedOff
            size={14}
            className={css({
              ...iconStyle,
              marginTop: "-2px",
              color: theme.colors.negative,
            })}
          />
        )}
        {children ? "Proces aktywny" : "Proces nieaktywny"}
        <span hidden>{children.toString()}</span>
      </Block>
    );
  else if (dataType === DataType.Boolean)
    return (
      <Block>
        {children ? (
          <CircleCheck
            size={14}
            className={css({
              ...iconStyle,
              marginTop: "-2px",
              color: theme.colors.positive,
            })}
          />
        ) : (
          <CircleX
            size={14}
            className={css({
              ...iconStyle,
              marginTop: "-2px",
              color: theme.colors.negative,
            })}
          />
        )}
        {children ? "Tak" : "Nie"}
        <span hidden>{children.toString()}</span>
      </Block>
    );
  else if (dataType === DataType.Calories) return <span>{children} kcal</span>;
  else if (dataType === DataType.CategoryKind)
    return (
      <>
        {children === CategoryKind.Diet ? (
          "Rodzaj diety"
        ) : children === CategoryKind.Meal ? (
          "Rodzaj posiłku"
        ) : children === CategoryKind.Cuisine ? (
          "Rodzaj kuchni"
        ) : children === CategoryKind.Occasion ? (
          "Okazja"
        ) : (
          <div
            className={css({
              display: "flex",
              alignItems: "center",
              color: "#999999",
              ...$style,
            })}
          >
            <Minus
              color="#999999"
              size={18}
              className={css({ marginRight: "5px" })}
            />
            Brak
          </div>
        )}
      </>
    );
  else if (dataType === DataType.Date)
    return <span>{new Date(String(children)).toLocaleDateString()}</span>;
  else if (dataType === DataType.DateTime)
    return (
      <span className={css({ ...$style })}>
        {new Date(String(children)).toLocaleString()}
      </span>
    );
  else if (dataType === DataType.Difficulty)
    return (
      <>
        {children === Difficulty.Easy ? (
          "Łatwy"
        ) : children === Difficulty.Medium ? (
          "Średnio-zaawansowany"
        ) : children === Difficulty.Hard ? (
          "Wymagający"
        ) : (
          <div
            className={css({
              display: "flex",
              alignItems: "center",
              color: "#999999",
              ...$style,
            })}
          >
            <Minus
              color="#999999"
              size={18}
              className={css({ marginRight: "5px" })}
            />
            Brak
          </div>
        )}
      </>
    );
  else if (dataType === DataType.Email)
    return (
      <StyledLink
        target="_blank"
        href={`mailto:${children}`}
        $style={{
          textDecoration: "underline dotted",
          fontWeight: 400,
          ...$style,
        }}
      >
        <Mail size={14} className={css(iconStyle)} />
        {children}
      </StyledLink>
    );
  else if (dataType === DataType.Gram) return <span>{children} g</span>;
  else if (dataType === DataType.InvertedBoolean)
    return (
      <Block>
        {children ? (
          <CircleX
            size={14}
            className={css({
              ...iconStyle,
              marginTop: "-2px",
              color: theme.colors.negative,
            })}
          />
        ) : (
          <CircleCheck
            size={14}
            className={css({
              ...iconStyle,
              marginTop: "-2px",
              color: theme.colors.positive,
            })}
          />
        )}
        {children ? "Tak" : "Nie"}
        <span hidden>{children.toString()}</span>
      </Block>
    );
  else if (dataType === DataType.Link)
    return (
      <StyledLink
        target="_blank"
        href={href || (children as string)}
        $style={{
          textDecoration: "underline dotted",
          fontWeight: 400,
          ...$style,
        }}
      >
        <World size={14} className={css(iconStyle)} />
        {children}
      </StyledLink>
    );
  else if (dataType === DataType.Login)
    return (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
        })}
      >
        <Key size={18} className={css({ marginRight: "5px" })} />
        {children}
      </div>
    );
  else if (dataType === DataType.Minutes) return <span>{children} min.</span>;
  else if (dataType?.includes(DataType.Model))
    return (
      <StyledLink
        {...(hasPermission &&
          !deletedAt && {
            href: href || `/${dataType.split(":")[1]}/${data}`,
            onClick: (event: MouseEvent) => {
              event.preventDefault();
              history.push(href || `/${dataType.split(":")[1]}/${data}`);
            },
          })}
        $style={{
          textDecoration: "underline dotted",
          fontWeight: 400,
          ...((!hasPermission || !!deletedAt) && {
            cursor: "not-allowed",
          }),
          ...$style,
        }}
      >
        <Tooltip
          {...(!!deletedAt && {
            content: `Usunięto ${new Date(deletedAt)?.toLocaleDateString()}`,
            placement: "left",
          })}
        >
          <span>
            {dataType?.includes(DataType.Allergens) ? (
              <CakeOff size={16} className={css(iconStyle)} />
            ) : dataType?.includes(DataType.Articles) ? (
              <News size={16} className={css(iconStyle)} />
            ) : dataType?.includes(DataType.Brands) ? (
              <Registered size={16} className={css(iconStyle)} />
            ) : dataType?.includes(DataType.Categories) ? (
              <Category size={16} className={css(iconStyle)} />
            ) : dataType?.includes(DataType.Dictionaries) ? (
              <Book size={16} className={css(iconStyle)} />
            ) : dataType?.includes(DataType.Images) ? (
              <Photo size={16} className={css(iconStyle)} />
            ) : dataType?.includes(DataType.Products) ? (
              <Carrot size={16} className={css(iconStyle)} />
            ) : dataType?.includes(DataType.Ingredients) ? (
              <Salad size={16} className={css(iconStyle)} />
            ) : dataType?.includes(DataType.Recipes) ? (
              <ChefHat size={16} className={css(iconStyle)} />
            ) : dataType?.includes(DataType.Roles) ? (
              <Shield size={16} className={css(iconStyle)} />
            ) : dataType?.includes(DataType.SeoTagsets) ? (
              <Seo size={16} className={css(iconStyle)} />
            ) : dataType?.includes(DataType.Slides) ? (
              <Slideshow size={16} className={css(iconStyle)} />
            ) : dataType?.includes(DataType.StaticPages) ? (
              <Article size={16} className={css(iconStyle)} />
            ) : dataType?.includes(DataType.Tags) ? (
              <Tags size={16} className={css(iconStyle)} />
            ) : dataType?.includes(DataType.Users) ? (
              <User size={16} className={css(iconStyle)} />
            ) : (
              <ExternalLink size={14} className={css(iconStyle)} />
            )}
            {children}
          </span>
        </Tooltip>
      </StyledLink>
    );
  else if (dataType === DataType.NotificationContent)
    return children ? (
      <span>(został) {children}</span>
    ) : (
      <span>(został) przeniesiony</span>
    );
  else if (dataType === DataType.Quota)
    return (
      <span className={css({ ...$style })}>
        {new Intl.NumberFormat("pl-PL", {
          style: "currency",
          currencyDisplay: "code",
          currency: "PLN",
        }).format(parseFloat(children as string))}
      </span>
    );
  else if (dataType === DataType.Pre)
    return (
      <pre
        className={css({
          marginTop: "0px",
          marginBottom: "0px",
          fontWeight: 400,
          fontSize: "14px",
          backgroundColor: theme.colors.backgroundSecondary,
          paddingLeft: theme.sizing.scale300,
          paddingRight: theme.sizing.scale300,
          paddingTop: theme.sizing.scale0,
          paddingBottom: theme.sizing.scale0,
          display: "inline",
          borderRadius: theme.borders.radius100,
          whiteSpace: "unset",
          ...$style,
        })}
      >
        {children}
      </pre>
    );
  else if (dataType === DataType.Subject)
    return (
      <span>
        {
          SEO_TAGSETS_SUBJECTS.find(
            ({ id }: { id: string; label: string }) => id === children
          )?.label
        }
      </span>
    );
  else if (dataType === DataType.StaticPageKind)
    return (
      <span>
        {
          STATIC_PAGE_KINDS.find(
            ({ id }: { id: string; label: string }) => id === children
          )?.label
        }
      </span>
    );
  else if (dataType === DataType.System)
    return (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
          color: "#999999",
        })}
      >
        <Ghost
          color="#999999"
          size={18}
          className={css({ marginRight: "5px" })}
        />
        System
      </div>
    );
  else if (dataType === DataType.VisibilityBoolean)
    return (
      <Block display="flex" alignItems="center">
        {children ? (
          <Eye
            size={14}
            className={css({
              ...iconStyle,
              color: theme.colors.positive,
            })}
          />
        ) : (
          <EyeOff
            size={14}
            className={css({
              ...iconStyle,
              color: theme.colors.negative,
            })}
          />
        )}
        {children ? "Tak" : "Nie"}
        <span hidden>{children.toString()}</span>
      </Block>
    );
  else if (dataType === DataType.UserStatus)
    return (
      <Block>
        {children === "Wysłano zaproszenie" ? (
          <UserExclamation
            size={14}
            className={css({
              ...iconStyle,
              marginTop: "-2px",
              color: theme.colors.warning,
            })}
          />
        ) : children === "Zablokowany" ? (
          <UserOff
            size={14}
            className={css({
              ...iconStyle,
              marginTop: "-2px",
              color: theme.colors.negative,
            })}
          />
        ) : (
          <UserCheck
            size={14}
            className={css({
              ...iconStyle,
              marginTop: "-2px",
              color: theme.colors.positive,
            })}
          />
        )}
        {children}
      </Block>
    );
  else return <span className={css({ ...$style })}>{children}</span>;
}
