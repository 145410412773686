import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { ConfirmDialog } from "components/confirm-dialog";
import { Content } from "components/content";
import { Header } from "components/header";
import { NoPermissionsRedirect } from "components/no-permissions-redirect";
import { ObjectViewRenderer } from "components/object-view-renderer";
import { useAuth } from "contexts/auth-context";
import { useLoading } from "contexts/loading-context";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Pencil, Trash } from "tabler-icons-react";
import { PERMISSIONS } from "utils/permissions";

import { StaticPage } from "../static-pages";
import { STATIC_PAGES_FIELDS } from "../static-pages.form";
import { STATIC_PAGES_DELETE, STATIC_PAGES_SHOW } from "../static-pages.gql";

export default function StaticPagesShow(): React.ReactElement {
  const [, theme] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const { checkPermission } = useAuth();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const { setIsLoading, setIsFetching } = useLoading();

  const { refetch, data, error } = useQuery(STATIC_PAGES_SHOW, {
    variables: { id: id ? parseInt(id) : null },
    notifyOnNetworkStatusChange: true,
  });
  const staticPage: StaticPage = data?.staticPage;

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    if (data?.staticPage) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => data?.staticPage && setIsFetching(false), [data]);

  const [deleteStaticPage] = useMutation(STATIC_PAGES_DELETE);

  const submit = async () => {
    setIsLoading(true);

    try {
      await deleteStaticPage({
        variables: {
          staticPageDeleteInput: { id: id ? parseInt(id) : null },
        },
      });

      enqueueSnackbar({
        message: "Usunięto pomyślnie",
        variant: "success",
      });

      setIsConfirmDialogOpen(false);

      history.push("/static-pages");
    } catch (error: unknown) {
      setIsConfirmDialogOpen(false);

      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!checkPermission(PERMISSIONS.staticPage.read))
    return <NoPermissionsRedirect />;

  return (
    <article>
      <Header
        title={staticPage?.name}
        labels={["Strony informacyjne", "Wyświetlanie"]}
        goBackOption
        buttons={[
          {
            label: "Edytuj",
            kind: KIND.secondary,
            startEnhancer: <Pencil size={18} />,
            permission: checkPermission(PERMISSIONS.staticPage.update),
            onClick: () => history.push(`/static-pages/${id}/update`),
          },
        ]}
        actions={[
          {
            label: "Usuń stronę informacyjną",
            icon: Trash,
            color: theme.colors.negative,
            onClick: () => setIsConfirmDialogOpen(true),
            permission: checkPermission(PERMISSIONS.staticPage.delete),
          },
        ]}
      />
      <Content>
        {data && (
          <ObjectViewRenderer<StaticPage>
            fields={STATIC_PAGES_FIELDS}
            data={staticPage}
          />
        )}
        <ConfirmDialog
          isOpen={isConfirmDialogOpen}
          label={`Usunięcie strony informacyjnej ${staticPage?.name}`}
          close={() => setIsConfirmDialogOpen(false)}
          confirm={submit}
        />
      </Content>
    </article>
  );
}
