import { BasicFilter, FilterType } from "./../../filters.d";
import { StaticPagesField } from "./static-pages.form";

export const STATIC_PAGES_FILTERS: BasicFilter[] = [
  {
    id: StaticPagesField.Name,
    label: "Nazwa",
    type: FilterType.Text,
  },
  {
    id: StaticPagesField.SystemName,
    label: "Funkcja",
    type: FilterType.StaticPageKind,
  },
  {
    id: StaticPagesField.CreatedAt,
    label: "Data utworzenia",
    type: FilterType.Date,
  },
  {
    id: StaticPagesField.IsVisible,
    label: "Widoczność",
    type: FilterType.Boolean,
  },
  {
    id: StaticPagesField.ShowInHeader,
    label: "Pokaż w menu",
    type: FilterType.Boolean,
  },
  {
    id: StaticPagesField.HeaderPosition,
    label: "Pozycja w menu",
    type: FilterType.HeaderPosition,
  },
];
