import { DataType } from "components/formatted-value";
import { SelectValue } from "components/select/select";
import { SeoTagsetsField } from "containers/SeoTagsets/seo-tagsets.form";

import { DictionarySystemName } from "../../constants";
import { FieldsGroup, FieldType } from "../../fields.d";

export type StaticPagesFormInputs = {
  name: string;
  slug: string;
  content: string;
  redirectUrl: string;
  isVisible: boolean;
  systemName: SelectValue;
  metaTitle: string;
  metaDescription: string;
  metaKeywords: string;
  canonical: string;
  robots: SelectValue;
  ogTitle: string;
  ogDescription: string;
  showTableOfContents: boolean;
  showLinkInFooter: boolean;
  showInHeader: boolean;
  headerPosition: number | null;
};

export enum StaticPagesField {
  Name = "name",
  Slug = "slug",
  Content = "content",
  CreatedAt = "createdAt",
  UpdatedAt = "updatedAt",
  IsVisible = "isVisible",
  RedirectUrl = "redirectUrl",
  SeoTagset = "seoTagset",
  SystemName = "systemName",
  ShowTableOfContents = "showTableOfContents",
  ShowLinkInFooter = "showLinkInFooter",
  ShowInHeader = "showInHeader",
  HeaderPosition = "headerPosition",
}

export const STATIC_PAGES_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: StaticPagesField.Name,
        label: "Nazwa",
        type: FieldType.BigInput,
        span: 12,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
      },
      {
        id: StaticPagesField.Slug,
        label: "Slug",
        type: FieldType.SlugInput,
        dataType: DataType.Pre,
        info: "staticPageSlug",
        span: 12,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
        caption:
          "Slug jest generowany automatycznie na podstawie nazwy. Możesz go zmienić, edytując powyższe pole.",
      },
      {
        id: StaticPagesField.Slug,
        label: "Link publiczny",
        type: FieldType.Link,
        span: 12,
        show: { visible: true },
        caption:
          "Link do udostępnienia. Uwaga: Jeśli widoczność jest ustawiona na „nie”, pozycja nie jest dostępna publicznie",
        create: { visible: false, required: false },
        update: { visible: false, required: false },
      },
      {
        id: StaticPagesField.IsVisible,
        label: "Widoczność",
        type: FieldType.Switch,
        dataType: DataType.VisibilityBoolean,
        readonly: true,
        span: 4,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: StaticPagesField.ShowLinkInFooter,
        label: "Pokaż link w stopce",
        type: FieldType.Switch,
        dataType: DataType.Boolean,
        readonly: true,
        span: 4,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: StaticPagesField.ShowTableOfContents,
        label: "Pokaż spis treści",
        type: FieldType.Switch,
        dataType: DataType.Boolean,
        caption: "Budowany na podstawie Nagłówków o poziomie 3.",
        readonly: true,
        span: 4,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: StaticPagesField.ShowInHeader,
        label: "Pokaż w menu",
        type: FieldType.Switch,
        dataType: DataType.Boolean,
        info: "headerPosition",
        span: 4,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
        caption: "Określa czy strona jest widoczna w menu",
      },
      {
        id: StaticPagesField.HeaderPosition,
        label: "Pozycja w menu",
        type: FieldType.NumberInput,
        info: "showInHeader",
        span: 4,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
        caption: "Określa kolejność wyświetlania w menu po artykułach",
      },
      {
        id: StaticPagesField.RedirectUrl,
        label: "Link do przekierowania",
        type: FieldType.Input,
        dataType: DataType.Link,
        span: 8,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: StaticPagesField.SystemName,
        label: "Funkcja",
        type: FieldType.StaticPageKind,
        dataType: DataType.StaticPageKind,
        span: 4,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
    ],
  },
  {
    id: "content",
    label: "Treść",
    fields: [
      {
        id: StaticPagesField.Content,
        label: "Treść",
        span: 12,
        type: FieldType.Editor,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
    ],
  },
  {
    id: "seo",
    label: "Tagi SEO",
    fields: [
      {
        id: SeoTagsetsField.MetaTitle,
        label: "Tytuł meta",
        caption: "Maks. 70 znaków ze spacjami",
        maxLength: 70,
        span: 6,
        type: FieldType.Input,
        show: {
          visible: true,
          accessor: [StaticPagesField.SeoTagset, SeoTagsetsField.MetaTitle],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.OgTitle,
        label: "Tytuł OG",
        caption: "Maks. 70 znaków ze spacjami",
        maxLength: 70,
        span: 6,
        type: FieldType.Input,
        show: {
          visible: true,
          accessor: [StaticPagesField.SeoTagset, SeoTagsetsField.OgTitle],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.MetaDescription,
        label: "Opis meta",
        caption: "Maks. 256 znaków ze spacjami",
        maxLength: 256,
        span: 6,
        type: FieldType.TextArea,
        show: {
          visible: true,
          accessor: [
            StaticPagesField.SeoTagset,
            SeoTagsetsField.MetaDescription,
          ],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.OgDescription,
        label: "Opis OG",
        caption: "Maks. 256 znaków ze spacjami",
        maxLength: 256,
        span: 6,
        type: FieldType.TextArea,
        show: {
          visible: true,
          accessor: [StaticPagesField.SeoTagset, SeoTagsetsField.OgDescription],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.MetaKeywords,
        label: "Słowa kluczowe meta",
        caption: "Wpisz po przecinku",
        span: 12,
        type: FieldType.Input,
        show: {
          visible: true,
          accessor: [StaticPagesField.SeoTagset, SeoTagsetsField.MetaKeywords],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.Robots,
        label: "Robots",
        span: 4,
        type: FieldType.DictionaryValuesSelect,
        dictionarySystemName: DictionarySystemName.RobotsSeoTagsets,
        show: {
          visible: true,
          accessor: [StaticPagesField.SeoTagset, SeoTagsetsField.Robots],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.Canonical,
        label: "Link canonical",
        span: 8,
        type: FieldType.Input,
        show: {
          visible: true,
          accessor: [StaticPagesField.SeoTagset, SeoTagsetsField.Canonical],
        },
        create: { visible: true },
        update: { visible: true },
      },
    ],
  },
  {
    id: "system",
    label: "Dane systemowe",
    fields: [
      {
        id: StaticPagesField.CreatedAt,
        label: "Utworzenie",
        span: 6,
        type: FieldType.Input,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: StaticPagesField.UpdatedAt,
        label: "Ostatnia aktualizacja",
        span: 6,
        type: FieldType.Input,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
    ],
  },
];
