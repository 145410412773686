import {
  API,
  BlockAPI,
  BlockTool,
  BlockToolConstructorOptions,
  BlockToolData,
  ToolboxConfig,
} from "@editorjs/editorjs";

import { useImageLibrary } from "../../../contexts/image-library-context";

interface GalleryData extends BlockToolData {
  imageUrls: string[];
  isStretched?: boolean;
}

interface GalleryConfig {
  imageLibraryContext: ReturnType<typeof useImageLibrary>;
}

type GalleryNodes = {
  button?: HTMLButtonElement;
  imagesContainer?: HTMLDivElement;
};

type GallerySetting = {
  icon: string;
  name: GallerySettingName;
  label: string;
};

enum GallerySettingName {
  IsStretched = "isStretched",
}

const STYLESHEET = `
  .images-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 10px;
  }

  .images-container > div {
    width: 100%;
    height: 120px;
    background-position: center center;
    background-size: auto 90%;
    background-repeat: no-repeat;
    position: relative;
  }

  .images-container > div > button {
    background-color: rgb(239, 56, 48);
    top: 0;
    right: 0;
    position: absolute;
    display: none;
  }

  .images-container > div:hover > button {
    display: block;
  }
`;

export default class DesktopGallery implements BlockTool {
  public static isReadOnlySupported = true;
  public static enableLineBreaks = true;

  private _data: GalleryData;
  private isReadOnly: boolean;
  private api: API;
  private config?: GalleryConfig;
  private nodes: GalleryNodes;
  private block?: BlockAPI;

  static get toolbox(): ToolboxConfig {
    return {
      title: "Galeria desktop",
      icon: `
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 19V19C9.13623 19 8.20435 19 7.46927 18.6955C6.48915 18.2895 5.71046 17.5108 5.30448 16.5307C5 15.7956 5 14.8638 5 13V12C5 9.19108 5 7.78661 5.67412 6.77772C5.96596 6.34096 6.34096 5.96596 6.77772 5.67412C7.78661 5 9.19108 5 12 5H13.5C14.8956 5 15.5933 5 16.1611 5.17224C17.4395 5.56004 18.44 6.56046 18.8278 7.83886C19 8.40666 19 9.10444 19 10.5V10.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16 13V16M16 19V16M19 16H16M16 16H13" stroke="black" stroke-width="2" stroke-linecap="round"/>
      <path d="M6.5 17.5L17.5 6.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M18.9919 10.5H19.0015" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M10.9919 19H11.0015" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M5 13L13 5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    `,
    };
  }

  constructor({
    data,
    readOnly,
    api,
    config,
    block,
  }: BlockToolConstructorOptions<GalleryData, GalleryConfig>) {
    this.isReadOnly = readOnly;
    this.api = api;
    this.config = config;
    this.block = block;

    this.nodes = {
      button: undefined,
      imagesContainer: undefined,
    };

    this._data = {
      ...data,
      imageUrls: data.imageUrls || [],
      isStretched: !!data.isStretched,
    };
  }

  get settings(): GallerySetting[] {
    return [
      {
        icon: `
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17 9L20 12L17 15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M14 12H20" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7 9L4 12L7 15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4 12H10" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      `,
        name: GallerySettingName.IsStretched,
        label: "Rozszerz/Zwęź",
      },
    ];
  }

  get data(): GalleryData {
    return this._data;
  }

  set data(data: GalleryData) {
    this._data = { ...this.data, ...data };

    if (this.block) {
      this.block.stretched = !!data.isStretched;
    }

    this.renderImages();
  }

  private renderImages() {
    if (this.nodes.imagesContainer) {
      const children: Node[] = [];

      for (const imageUrl of this.data.imageUrls) {
        const image = document.createElement("div");
        image.classList.add(this.api.styles.block);
        image.style.backgroundImage = `url(${imageUrl})`;

        if (!this.isReadOnly) {
          const removeImage = (event: Event) => {
            event.preventDefault();

            this.data = {
              ...this.data,
              imageUrls: this.data.imageUrls.filter((url) => url !== imageUrl),
            };
          };

          const button = document.createElement("button");

          button.classList.add(this.api.styles.settingsButton);
          button.innerHTML = "X";
          button.addEventListener("click", removeImage);

          image.appendChild(button);
        }

        children.push(image);
      }

      // TypeScript throws an error that `replaceChildren` is not available on HTMLDivElement which is not true
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.nodes.imagesContainer.replaceChildren(...children);
    }
  }

  render(): HTMLElement {
    const container = document.createElement("div");
    container.classList.add(this.api.styles.block);
    container.style.textAlign = "center";

    const style = document.createElement("style");
    style.appendChild(document.createTextNode(STYLESHEET));

    const openGalleryLibrary = (event: Event) => {
      event.preventDefault();

      this.config?.imageLibraryContext.open((image) => {
        const url = image?.url;

        this.data = {
          ...this.data,
          imageUrls: [...this.data.imageUrls, url],
        };

        this.api.blocks.insert(undefined, undefined, undefined, Infinity);
        this.api.blocks.delete(Infinity);
      });
    };

    const button = document.createElement("button");

    button.classList.add(this.api.styles.button);
    button.innerHTML = "Wgraj lub wybierz z biblioteki (galeria desktop)";
    button.addEventListener("click", openGalleryLibrary);

    const imagesContainer = document.createElement("div");
    imagesContainer.classList.add(this.api.styles.block, "images-container");

    container.appendChild(style);
    container.appendChild(imagesContainer);

    if (!this.isReadOnly) {
      container.appendChild(button);

      this.nodes.button = button;
    }

    this.nodes.imagesContainer = imagesContainer;

    this.renderImages();

    // If the stretched prop is set without setTimeout, the block doesn't render
    setTimeout(() => {
      if (this.block) {
        this.block.stretched = !!this.data.isStretched;
      }
    }, 0);

    return container;
  }

  renderSettings() {
    return this.settings.map((setting: GallerySetting) => {
      return {
        icon: setting.icon,
        label: setting.label,
        isActive: this.data.isStretched ?? false,
        onActivate: () => this.toggleIsStretched(),
        closeOnActivate: true,
      };
    });
  }

  private toggleIsStretched(): void {
    this.data.isStretched = !this.data.isStretched;
    if (this.block) {
      this.block.stretched = !!this.data.isStretched;
    }
    this.api.blocks.update(
      (this.api.blocks.getBlockByIndex(
        this.api.blocks.getCurrentBlockIndex()
      ) as BlockAPI)?.id,
      this.data
    );
  }

  save(rootElement: HTMLDivElement): GalleryData {
    const caption = rootElement.querySelector(".caption")?.innerHTML || "";
    const alt = rootElement.querySelector(".alt")?.innerHTML || "";

    return {
      ...this.data,
      caption,
      alt,
    };
  }
}
